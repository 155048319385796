import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import {
    ArchwizardModule,
    NavigationMode,
    WizardComponent,
} from 'angular-archwizard';
import { TranslateService } from '@ngx-translate/core';
import { ClinicClient } from '../../../Services/clinic.service';
import { WaClient } from 'src/app/Services/Wa.service';
import swal from 'sweetalert';
import {
    AppintmentAPIClient,
    Appointment,
    CreateAppointment,
} from '../../../Services/appointment.service';
import { getPatient, sessionIsExpired } from 'src/app/Functions/Utilities';
import { Router } from '@angular/router';
import { AccountClient } from 'src/app/Services/accountService.service';
import { PaymobServiceService } from '../../../Services/paymobService.service';
import { AnimationFrameScheduler } from 'rxjs/internal/scheduler/AnimationFrameScheduler';
declare var $: any;

@Component({
    selector: 'app-clinics',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './clinics.component.html',
    styleUrls: ['./clinics.component.scss'],
})
export class ClinicsComponent implements OnInit {
    @ViewChild(WizardComponent)
    public wizard: WizardComponent;
    awWizardDirection: string;
    awWizardStepSymbol: '';
    clinicOrginallist: any[] = [];
    clinicFilterlist: any[] = [];
    doctorsOrginallist: any[] = [];
    doctorsFilterlist: any[] = [];
    serviceFilterlist: any[] = [];
    serviceOrginallist: any[] = [];
    appointmentList: any[] = [];
    searchControl: any = {
        clinic: '',
        doctor: '',
        service: '',
    };
    selectedsteps: any = {
        clinic: '',
        doctor: '',
        AppointmentDate: '',
        appointment: '',
        service: '',
    };
    constructor(
        public translate: TranslateService,
        private clinicClient: ClinicClient,
        private waClient: WaClient,
        private appintmentAPIClient: AppintmentAPIClient,
        private route: Router,
        private paymobServiceService: PaymobServiceService
    ) {
        this.getawWizardDirection();
        this.getClinics();
    }
    isValidDate(dateString) {
        const MinDate = new Date('1752-01-01');
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return regex.test(dateString) && MinDate < new Date(dateString);
    }
    selectApp(appointment) {
        this.selectedsteps.appointment = appointment;
        $('#inserAppModel').modal('show');
    }

    searchAppointment() {
        if (this.isValidDate(this.selectedsteps.AppointmentDate)) {
            let appSearchParam = {
                StartDate: this.selectedsteps.AppointmentDate,
                DoctorCode: this.selectedsteps.doctor.DoctorCode,
                IS_TeleMed: false,
                PatientCode: '',
                Lang: '',
            };

            this.appintmentAPIClient
                .appintmentAPI_GetPatientAppointments(appSearchParam)
                .subscribe((response: any) => {
                    this.appointmentList = [];
                    if (response.DoctorAppointments.length > 0) {
                        response.DoctorAppointments[0].DoctorAppointments.forEach(
                            (appointment) => {
                                var currentTime = new Date().getTime();
                                var DateOfApoo =
                                    this.selectedsteps.AppointmentDate +
                                    ' ' +
                                    appointment.startTime;
                                var AppTime = new Date(DateOfApoo).getTime();
                                if (currentTime < AppTime)
                                    this.appointmentList.push(appointment);
                            }
                        );
                    }
                });
        }
    }

    insertAppointment() {
        if (!sessionIsExpired()) {
            const appointment = {
                app_id: this.selectedsteps.AppointmentDate,
                slot_id: this.selectedsteps.appointment.Appo_Period.replaceAll(
                    'AM',
                    ''
                ).replaceAll('PM', ''),
                pat_code: getPatient()?.Patient_Code,
                pat_nameAr: getPatient()?.Patient_Name_Ar,
                identity_no: getPatient()?.ID_Number,
                mobile_no: getPatient()?.Patient_Mobile,
                pat_age: getPatient()?.Patient_Age_Year,
                dr_code: this.selectedsteps.doctor.DoctorCode,
                CinicDept_Code: this.selectedsteps.clinic.Clinic_Code,
            };
            this.appintmentAPIClient
                .appintmentAPI_Reschedule(appointment)
                .subscribe((responseApp: any) => {
                    if (responseApp.Success) {
                        const BookingID = responseApp.BookingID;
                        let slot =
                            this.selectedsteps.appointment.Appo_Period.replaceAll(
                                'AM',
                                ''
                            ).replaceAll('PM', '');
                        const slot_id = slot.toString().split('-');

                        const getSeconds = (s) =>
                            s
                                .split(':')
                                .reduce((acc, curr) => acc * 60 + +curr, 0);

                        var seconds1 = getSeconds(slot_id[0]);
                        var seconds2 = getSeconds(slot_id[1]);
                        var res = Math.abs(seconds2 - seconds1);
                        var seconds = res % 60;
                        let Duration = seconds;
                        const service = {
                            Fk_Appo_ID: BookingID,
                            ServiceCode: this.selectedsteps.service.ServiceCode,
                            ServicePrice:
                                this.selectedsteps.service.ServiceAmount,
                            Duration: Duration ?? 30,
                            Duration_Count: 1,
                        };

                        this.appintmentAPIClient
                            .appintmentAPI_insert_AppointmentServices(service)
                            .subscribe((responseCreateService: any) => {
                                if (responseCreateService.Success) {
                                    this.searchAppointment();
                                    swal({
                                        title: this.translate.instant(
                                            'inserAppSuccessfully'
                                        ),
                                        text:
                                            this.translate.currentLang == 'en'
                                                ? responseApp.MessageBodyEn
                                                : responseApp.MessageBodyAr,
                                        icon: 'success',
                                        timer: 4000,
                                        buttons: {},
                                    }).then(
                                        () => {
                                            $('#inserAppModel').modal('hide');
                                        },
                                        (dismiss) => {
                                            if (dismiss === 'timer') {
                                            }
                                        }
                                    );
                                }
                            });
                    }
                });
        } else {
            $('#loginFirstModel').modal('show');
            $('#inserAppModel').modal('hide');
        }
    }
    goToSignin() {
        $('#inserAppModel').modal('hide');
        $('#loginFirstModel').modal('hide');
        this.route.navigate(['sign-in']);
    }
    previousDate() {
        if (this.selectedsteps.AppointmentDate != '') {
            var result = new Date(this.selectedsteps.AppointmentDate);
            result.setDate(result.getDate() - 1);
            this.selectedsteps.AppointmentDate = result
                .toISOString()
                .substr(0, 10);
            this.searchAppointment();
        }
    }
    NextDate() {
        if (this.selectedsteps.AppointmentDate != '') {
            var result = new Date(this.selectedsteps.AppointmentDate);
            result.setDate(result.getDate() + 1);
            this.selectedsteps.AppointmentDate = result
                .toISOString()
                .substr(0, 10);
            this.searchAppointment();
        }
    }

    sessionIsExpired() {
        return sessionIsExpired();
    }
    search() {
        this.clinicFilterlist = this.clinicOrginallist.filter((clinic) => {
            if (this.translate.currentLang == 'en')
                return clinic.CodeDescriptionEn.toLowerCase().includes(
                    this.searchControl.clinic?.trim().toLowerCase()
                );
            else
                return clinic.CodeDescriptionAr.toLowerCase().includes(
                    this.searchControl.clinic?.trim().toLowerCase()
                );
        });
        this.serviceFilterlist = this.serviceOrginallist.filter((service) => {
            if (this.translate.currentLang == 'en')
                return service.ServiceNameEn.toLowerCase().includes(
                    this.searchControl.service?.trim().toLowerCase()
                );
            else
                return service.ServiceNameAr.toLowerCase().includes(
                    this.searchControl.service?.trim().toLowerCase()
                );
        });

        this.doctorsFilterlist = this.doctorsOrginallist.filter((clinic) => {
            if (this.translate.currentLang == 'en')
                return clinic.DoctorNameEn.toLowerCase().includes(
                    this.searchControl.doctor?.trim().toLowerCase()
                );
            else
                return clinic.DoctorNameAr.toLowerCase().includes(
                    this.searchControl.doctor?.trim().toLowerCase()
                );
        });
    }
    getawWizardDirection() {
        if (document.dir == 'rtl') {
            this.awWizardDirection = 'rtl';
        } else {
            this.awWizardDirection = 'ltr';
        }
    }

    getClinics() {
        this.clinicClient.clinic_GetClinics({}).subscribe((response: any) => {
            this.clinicOrginallist = response.Clinics;
            this.clinicFilterlist = response.Clinics;
        });
    }

    getClinicName(clinic: any) {
        return this.translate.currentLang == 'en'
            ? clinic?.ClinicDept_Name_En ?? ''
            : clinic?.ClinicDept_Name_Ar ?? '';
    }

    getClinicDeptName(doctor: any) {
        return this.translate.currentLang == 'en'
            ? doctor?.ClinicDept_Name_En ?? ''
            : doctor?.ClinicDept_Name_Ar ?? '';
    }
    getSpecialization(doctor: any) {
        return this.translate.currentLang == 'en'
            ? doctor?.SpecializationEn ?? ''
            : doctor?.SpecializationAr ?? '';
    }

    selectClinic(clinic: any) {
        this.selectedsteps.clinic = clinic;
    }
    selectDoctor(doctor: any) {
        this.selectedsteps.doctor = doctor;
    }
    selectService(service: any) {
        this.selectedsteps.service = service;
    }

    getDoctorName(doctor: any) {
        return this.translate.currentLang == 'en'
            ? doctor?.DoctorNameEn ?? ''
            : doctor?.DoctorNameAr ?? '';
    }
    getServiceName(service: any) {
        return this.translate.currentLang == 'en'
            ? service?.ServiceNameEn ?? ''
            : service?.ServiceNameAr ?? '';
    }

    goToAppointementStep() {
        if (this.selectedsteps.doctor != '') {
            this.wizard.goToNextStep();
        } else {
            swal({
                title: this.translate.instant('gotoAooStepFail'),
                text: this.translate.instant('gotoAppStepFailReason'),
                icon: 'error',
                timer: 4000,
                buttons: {},
            });
        }
    }
    goToDoctorStep() {
        if (this.selectedsteps.service != '') {
            this.getLocation().then((resp) => {
                let doctorParam = {
                    DoctorSpeciality: this.selectedsteps.clinic.CodeUnique,
                    ClinicDept_Code: this.selectedsteps.clinic.Clinic_Code,
                    Doctor_Code: null,
                    // Long: resp.long,
                    // Lat: resp.lat,
                    // DistanceKilos: '1000',
                    Long: 0,
                    Lat: 0,
                    DistanceKilos: null,
                    CurrentPage: null,
                    PageSize: null,
                    UseLocation: null,
                };
                this.waClient
                    .wa_GetDoctors(doctorParam)
                    .subscribe((response: any) => {
                        this.doctorsOrginallist = response.Doctors;
                        this.doctorsFilterlist = response.Doctors;
                        this.wizard.goToNextStep();
                    });
            });
        } else {
            swal({
                title: this.translate.instant('gotodoctorStepFail'),
                text: this.translate.instant('gotodoctorStepFailReason'),
                icon: 'error',
                timer: 4000,
                buttons: {},
            });
        }
    }
    goToStep() {
        if (this.selectedsteps.clinic != '') {
            const serviceParam = {
                ClinicDeptCode: this.selectedsteps.clinic.Clinic_Code,
            };
            this.waClient
                .wa_GetServiceByClinicCode(serviceParam)
                .subscribe((response: any) => {
                    this.serviceFilterlist = response.Servicelist;
                    this.serviceOrginallist = response.Servicelist;
                    this.wizard.goToNextStep();
                });
        } else {
            swal({
                title: this.translate.instant('gotoserviceStepFail'),
                text: this.translate.instant('gotoserviceStepFailReason'),
                icon: 'error',
                timer: 4000,
                buttons: {},
            });
        }
    }
    goToServiceStep() {
        if (this.selectedsteps.clinic != '') {
            const serviceParam = {
                ClinicDeptCode: this.selectedsteps.clinic.Clinic_Code,
            };
            this.waClient
                .wa_GetServiceByClinicCode(serviceParam)
                .subscribe((response: any) => {
                    this.serviceFilterlist = response.Servicelist;
                    this.serviceOrginallist = response.Servicelist;
                    this.wizard.goToNextStep();
                });
        } else {
            swal({
                title: this.translate.instant('gotoserviceStepFail'),
                text: this.translate.instant('gotoserviceStepFailReason'),
                icon: 'error',
                timer: 4000,
                buttons: {},
            });
        }
    }
    getLocation(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((resp) => {
                resolve({
                    long: resp.coords.longitude,
                    lat: resp.coords.latitude,
                });
                reject(() => {
                    swal({
                        title: this.translate.instant('gotodoctorStepFail'),
                        text: this.translate.instant('openLocation'),
                        icon: 'error',
                        timer: 4000,
                        buttons: {},
                    });
                });
            });
        });
    }
    ngOnInit(): void {}
}
