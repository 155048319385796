

<!-- Footer bottom Area -->
<div class="footer-bottom mt-3">
    <div class="container">
        <p>جميع الحقوق محفوظة لدى
            <a href="https://nitcotek.com/" target="_blank">الشركة الوطنية لتقنية المعلومات</a>
            &copy; 2023
            </p>
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->
