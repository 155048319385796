import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountClient} from './Services/accountService.service'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { API_BASE_URL } from './Services/accountService.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './Services/ErrorInterceptor.service';
import { IHttpInterceptorService } from './Services/IHttpInterceptorService.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SessionTimeoutComponent } from './components/pages/session-timeout/session-timeout/session-timeout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClinicsComponent } from './components/pages/clinics/clinics.component';
import { ArchwizardModule,NavigationMode  } from 'angular-archwizard';
import { ClinicClient } from './Services/clinic.service';
import { WaClient } from './Services/Wa.service';
import { AppintmentAPIClient } from './Services/appointment.service';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { AppointmentsComponent } from './components/pages/_Appointments/appointments/appointments.component';
import { VisitDetailsComponent } from './components/pages/_Appointments/visit-details/visit-details.component';
import { VisitClient } from './Services/Visit.service';
import { PackagesComponent } from './components/pages/packages/packages.component';
import { VacationsComponent } from './components/pages/vacations/vacations.component';
import { VaccinationsComponent } from './components/pages/vaccinations/vaccinations.component';
import { OfferAndPackagesClient } from './Services/OfferAndPackages.service';
import { SMSService } from './Services/SMSService';
import { BranchesComponent } from './components/pages/branches/branches.component';
import { PaymobServiceService } from './Services/paymobService.service';

export function rootLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HomeOneComponent,
        PreloaderComponent,
        NavbarComponent,
        FooterComponent,
        SignInComponent,
        SignUpComponent,
        PrivacyPolicyComponent,
        ComingSoonComponent,
        ErrorComponent,
        ServicesComponent,
        ContactComponent,
        SessionTimeoutComponent,
        ClinicsComponent,
        ProfileComponent,
        AppointmentsComponent,
        VisitDetailsComponent,
        PackagesComponent,
        VacationsComponent,
        VaccinationsComponent,
        BranchesComponent,
    ],
    imports: [
        ArchwizardModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserModule,
        AppRoutingModule,
        BrowserModule,
        AppRoutingModule,
        NgSelectModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forRoot([]),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: rootLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
    ],
    providers: [
        HttpClient,
        AccountClient,
        ClinicClient,
        WaClient,
        AppintmentAPIClient,
        VisitClient,
        OfferAndPackagesClient,
        SMSService,
        PaymobServiceService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: IHttpInterceptorService,
            multi: true,
        },
        {
            provide: API_BASE_URL,
            useValue: 'http://nitcoapi.british-hospital.com',
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
